body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /*font-family: 'Courier New', monospace;*/
  /*font-family: "Lucida Grande","Roboto", "Helvetica","Arial","sans-serif";*/
  /*font-family: 'Quicksand', sans-serif;*/
  /*font-family: 'Ubuntu', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  line-height: 1.35em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100vh;
  max-height: 100vh;

  min-width: 875px;
}

code {
  font-family: 'Inconsolata', monospace;
}

code::after {
  content: '';
  display: block;
}

/* Had to add this because the content-picker label was showing in front of modals  */
#content-type-picker-label {
  z-index: 0;
}

.tool-spec:hover {
  background: #dddddd;
}

a {
  text-decoration: none;
  color: #2E5090;
}

a:hover {
  color: #6082C2;
}

/*
pretty annoying...  can't figure out another way to override hard-coded
min-width in react-drag-drop-files component.
 */
.react-drop-box {
  min-width: 125px !important;
}

.ippe-react-markdown p {
  line-height: 1.35em;
  margin-bottom: 10px;
}

.ippe-react-markdown-compact p {
  margin: 0px;
}

.ippe-react-markdown-compact p:not(:first-child) {
  margin-top: 10px;
}

.ippe-react-markdown-compact ul:last-child {
  margin-bottom: 0px;
}

.ippe-react-markdown-compact ul {
  padding-inline-start: 15px;
}

.ippe-markdown-code-style {
  font-family: 'Inconsolata', monospace;
  background: rgba(0, 0, 0, 5%);

  padding: 1px 3px 1px 3px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 20%);
  white-space: nowrap;
}

/*
I'd rather safari behave the same as other browsers, and (in situations in which I believe a tooltip is necessary)
simply be forced to add it myself.

https://stackoverflow.com/questions/20974276/prevent-safari-from-showing-tooltip-when-text-overflow-is-hidden-with-ellipsis
 */
.prevent-safari-tooltip::after {
     content: '';
     display: block;
 }


/* see note in index.js theme override */
.MuiAlert-standardInfo > .MuiAlert-icon {
  color: #2E5090 !important;
}